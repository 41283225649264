import React from 'react'
import { FC, ReactNode } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import useAuth from '../hooks/useAuth'
import { useEffect } from 'react'
import { Auth } from 'aws-amplify'

interface AuthGuardProps {
  children?: ReactNode
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      ;(async () => {
        const session = await Auth.currentSession()
        const userToken = `Bearer ${session.getAccessToken().getJwtToken()}`
        window.localStorage.setItem('userToken', userToken)
      })()
    }
  })

  if (!isAuthenticated) {
    return <Redirect to="/login" />
  }

  return <>{children}</>
}

AuthGuard.propTypes = {
  children: PropTypes.node,
}

export default AuthGuard
