export const capitalizeFirstLetter = (str: string) => {
  if (str.length) {
    const capitalizeFirstLetterString = str.charAt(0).toUpperCase() + str.slice(1)
    return capitalizeFirstLetterString
  } else {
    return str
  }
}

export const turnStringIntoArray = (str: string, splitOn: string) => {
  // removes all white spaces between string words then splits the str into an array.
  return str.replace(/\s+/g, '').split(splitOn)
}

export const turnFileSizeIntoMegaBytes = (fileSize: number) => {
  return fileSize / (1024 * 1024)
}

export const copyText = (text: string) => {
  navigator.clipboard.writeText(text)
}

export const turnHtmlToText = (htmlString: string) => {
  return htmlString.replace(/<[^>]+>/g, '')
}
