import React, { useEffect } from 'react'
import { FC, ReactNode } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { Box, Divider, Drawer, Hidden, Link, List, ListSubheader, makeStyles } from '@material-ui/core'
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined'
import DnsIcon from '@material-ui/icons/Dns'
import LabelIcon from '@material-ui/icons/Label'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel'
import TicketingIcon from '@material-ui/icons/ImportContacts'
import CouponCodeIcon from '@material-ui/icons/CropFree'
import Logo from 'src/components/Logo'
import useAuth from 'src/hooks/useAuth'
import NavItem from './NavItem'
import StorefrontIcon from '@material-ui/icons/Storefront'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import EventIcon from '@material-ui/icons/Event'

interface NavBarProps {
  onMobileClose: () => void
  openMobile: boolean
}

interface Item {
  href?: string
  icon?: ReactNode
  info?: ReactNode
  items?: Item[]
  title: string
}

interface Section {
  items: Item[]
  subheader: string
}

const sections: Section[] = [
  {
    subheader: '',
    items: [
      {
        title: 'Offers',
        icon: ReceiptIcon,
        href: '/app/management/offers',
      },
      {
        title: 'Categories',
        icon: DnsIcon,
        href: '/app/management/categories',
      },
      {
        title: 'Brands',
        icon: LabelIcon,
        href: '/app/management/brands',
      },
      {
        title: 'Stores',
        icon: StorefrontIcon,
        href: '/app/management/stores',
      },
      {
        title: 'Banners & Advertisements',
        icon: ViewCarouselIcon,
        href: '/app/management/banners',
      },
      {
        title: 'Events',
        icon: EventIcon,
        href: '/app/management/events',
      },
      {
        title: 'Partners Coupons',
        icon: CouponCodeIcon,
        href: '/app/management/couponsUsage',
      },
      {
        title: 'Internal Discount Codes',
        icon: CouponCodeIcon,
        href: '/app/management/internalCodes',
      },
      {
        title: 'Ticketing',
        icon: TicketingIcon,
        href: '/app/management/ticketing',
      },
      {
        title: 'Shop Corner',
        icon: ShoppingCartOutlinedIcon,
        href: '/app/management/shopify',
      },
      //<== Commented out to be used when applying Products and Customers ==>//
      // {
      //   title: 'Products',
      //   icon: ShoppingCartIcon,
      //   href: '/app/management/products',
      // },
      // {
      //   title: 'Customers',
      //   icon: UsersIcon,
      //   href: '/app/management/customers',
      // },
    ],
  },
]

function renderNavItems({ items, pathname, depth = 0 }: { items: Item[]; pathname: string; depth?: number }) {
  return (
    <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}</List>
  )
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: any[]
  pathname: string
  item: Item
  depth: number
}) {
  const key = item.title + depth

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    })

    acc.push(
      <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    )
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} />)
  }

  return acc
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}))

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles()
  const location = useLocation()
  const { user } = useAuth()

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          {/*<== Commented out to be used when enabling admin to edit their account info ==>*/}
          {/* <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            </RouterLink>
          </Box> */}
          <Box mt={2} textAlign="center">
            <Link variant="h5" color="textPrimary" underline="none">
              {user.name}
            </Link>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map(section => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}

export default NavBar
