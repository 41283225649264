import React, { useEffect, useState } from 'react'
import { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core'
import { Menu as MenuIcon } from 'react-feather'
import Logo from 'src/components/Logo'
import { DEFAULT_COUNTRY_CODE, ENDPOINTS, THEMES } from 'src/constants'
import { Theme } from 'src/theme'
import Account from './Account'
import Settings from './Settings'
import { CountryProps } from 'src/types/countries'
import UniversalCookie from 'universal-cookie'
import axiosInstance from 'src/utils/axios'
import { useSnackbar } from 'notistack'
interface TopBarProps {
  className?: string
  onMobileNavOpen?: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT_MODE
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEMES.DARK_MODE
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  countrySelectContainer: {
    paddingRight: 12,
  },
  toolbar: {
    minHeight: 64,
  },
}))

const TopBar: FC<TopBarProps> = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles()
  const universalCookie = new UniversalCookie()
  const { enqueueSnackbar } = useSnackbar()

  const [countries, setCountries] = useState<CountryProps[]>([])
  const [selectedCountry, setSelectedCountry] = useState<string>(universalCookie.get('country_code'))

  const onChangeSelectedCountry = event => {
    const newSelectedCountryCode = event?.target?.value || DEFAULT_COUNTRY_CODE
    universalCookie.set('country_code', newSelectedCountryCode, {
      path: '/',
    })
    setSelectedCountry(newSelectedCountryCode)
    window.location.href = '/'
  }

  useEffect(() => {
    if (enqueueSnackbar) {
      axiosInstance
        .get(ENDPOINTS.countries.all)
        .then(countriesResponse => {
          const mappedCountries = countriesResponse?.data?.data.map(country => ({
            name: country.name,
            code: country.code,
          }))
          setCountries(mappedCountries || [])
        })
        .catch(() => {
          enqueueSnackbar('Unable to get countries list', { variant: 'error' })
        })
    }
  }, [enqueueSnackbar])

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Box className={classes.countrySelectContainer}>
          <FormControl variant="standard" style={{ minWidth: 220 }}>
            <InputLabel>Country</InputLabel>
            <Select required value={selectedCountry} onChange={onChangeSelectedCountry}>
              {countries.map(country => (
                <MenuItem value={country.code}>{country.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
}

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
}

export default TopBar
