import { combineReducers } from '@reduxjs/toolkit'
import { reducer as calendarReducer } from 'src/slices/calendar'
import { reducer as formReducer } from 'redux-form'

const rootReducer = combineReducers({
  calendar: calendarReducer,
  form: formReducer,
})

export default rootReducer
