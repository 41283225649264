import axios from 'axios'
import { Auth } from 'aws-amplify'
import UniversalCookie from 'universal-cookie'
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
})

axiosInstance.defaults.headers.common['Authorization'] = 'AUTH TOKEN'
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json'

axiosInstance.interceptors.request.use(function(config) {
  const universalCookie = new UniversalCookie()
  const countryCode = universalCookie.get('country_code') || 'UAE'
  const isAdmin = true
  config.params = { ...config.params, countryCode, isAdmin }
  return Auth.currentSession()
    .then(session => {
      config.headers.Authorization = `Bearer ${session.getAccessToken().getJwtToken()} `
      return Promise.resolve(config)
    })
    .catch(() => {
      return Promise.reject(config)
    })
})

export default axiosInstance
