import React from 'react'
import { FC } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import { StylesProvider, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import GlobalStyles from 'src/components/GlobalStyles'
import ScrollReset from 'src/components/ScrollReset'
import GoogleAnalytics from 'src/components/GoogleAnalytics'
import { AuthProvider } from 'src/contexts/AWSAuthContext'
import useSettings from 'src/hooks/useSettings'
import { createTheme } from 'src/theme'
import routes, { renderRoutes } from 'src/routes'
import UniversalCookies from 'universal-cookie'
import { DEFAULT_COUNTRY_CODE } from './constants'

const history = createBrowserHistory()

const App: FC = () => {
  const { settings } = useSettings()
  const universalCookies = new UniversalCookies()
  if (!universalCookies.get('country_code')) {
    universalCookies.set('country_code', DEFAULT_COUNTRY_CODE, {
      path: '/',
    })
  }

  if (!universalCookies.get('is_admin')) {
    universalCookies.set('is_admin', true, { path: '/' })
  }

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  })

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider dense maxSnack={3}>
            <Router history={history}>
              <AuthProvider>
                <GlobalStyles />
                <ScrollReset />
                <GoogleAnalytics />
                {renderRoutes(routes)}
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  )
}

export default App
