import React from 'react'
import { FC } from 'react'
import logo from '../assets/images/sum_logo.png'

interface LogoProps {
  [key: string]: any
}

const Logo: FC<LogoProps> = props => {
  return <img alt="Logo" src={logo} {...props} />
}
export default Logo
