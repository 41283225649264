import React, { Suspense, Fragment, lazy } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import DashboardLayout from 'src/layouts/DashboardLayout'
import DocsLayout from 'src/layouts/DocsLayout'
import LoadingScreen from 'src/components/LoadingScreen'
import AuthGuard from 'src/components/AuthGuard'
import GuestGuard from 'src/components/GuestGuard'

type Routes = {
  exact?: boolean
  path?: string | string[]
  guard?: any
  layout?: any
  component?: any
  routes?: Routes
}[]

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment
        const Layout = route.layout || Fragment
        const Component = route.component

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        )
      })}
    </Switch>
  </Suspense>
)

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView')),
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView')),
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView')),
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView')),
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView')),
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView')),
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView')),
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView')),
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView')),
      },
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/invoice/InvoiceListView')),
      },
      {
        exact: true,
        path: '/app/management/stores',
        component: lazy(() => import('src/views/stores/StoresListView')),
      },
      {
        exact: true,
        path: '/app/management/stores/create',
        component: lazy(() => import('src/views/stores/StoreDetailsView')),
      },
      {
        exact: true,
        path: '/app/management/stores/:storeId',
        component: lazy(() => import('src/views/stores/StoreDetailsView')),
      },
      {
        exact: true,
        path: '/app/management/offers',
        component: lazy(() => import('src/views/offers/OffersListView')),
      },
      {
        exact: true,
        path: '/app/management/offers/create',
        component: lazy(() => import('src/views/offers/OfferEditView')),
      },
      {
        exact: true,
        path: '/app/management/offers/:offerId',
        component: lazy(() => import('src/views/offers/OfferDetailsView')),
      },
      {
        exact: true,
        path: '/app/management/offers/:offerId/edit',
        component: lazy(() => import('src/views/offers/OfferEditView')),
      },
      {
        exact: true,
        path: '/app/management/categories',
        component: lazy(() => import('src/views/categories/CategoriesListView')),
      },
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/product/ProductListView')),
      },
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(() => import('src/views/product/ProductCreateView')),
      },
      {
        exact: true,
        path: '/app/management/brands',
        component: lazy(() => import('src/views/brand/BrandListView')),
      },
      {
        exact: true,
        path: '/app/management/brands/create',
        component: lazy(() => import('src/views/brand/BrandDetailsView')),
      },
      {
        exact: true,
        path: '/app/management/brands/:brandId',
        component: lazy(() => import('src/views/brand/BrandDetailsView')),
      },
      {
        exact: true,
        path: '/app/management/banners',
        component: lazy(() => import('src/views/banners')),
      },
      {
        exact: true,
        path: '/app/management/events',
        component: lazy(() => import('src/views/events')),
      },
      {
        exact: true,
        path: '/app/management/events/add',
        component: lazy(() => import('src/views/events/EventsList/AddEventView')),
      },
      {
        exact: true,
        path: '/app/management/events/events/:eventId',
        component: lazy(() => import('src/views/events/EventsList/EventDetailsView')),
      },
      {
        exact: true,
        path: '/app/management/couponsUsage',
        component: lazy(() => import('src/views/couponsUsage')),
      },
      {
        exact: true,
        path: '/app/management/internalCodes',
        component: lazy(() => import('src/views/InternalDiscountCodes')),
      },
      {
        exact: true,
        path: '/app/management/ticketing',
        component: lazy(() => import('src/views/ticketing')),
      },
      {
        exact: true,
        path: '/app/management/shopify',
        component: lazy(() => import('src/views/shopify')),
      },
      {
        exact: true,
        path: '/app/management/shopify/product/:productId',
        component: lazy(() => import('src/views/shopify/productDetails')),
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />,
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/management/customers" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />,
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView')),
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView')),
      },
      {
        exact: true,
        path: '/docs/environment-variables',
        component: lazy(() => import('src/views/docs/EnvironmentVariablesView')),
      },
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() => import('src/views/docs/DeploymentView')),
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() => import('src/views/docs/APICallsView')),
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() => import('src/views/docs/AnalyticsView')),
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() => import('src/views/docs/AuthenticationView')),
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() => import('src/views/docs/RoutingView')),
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() => import('src/views/docs/SettingsView')),
      },
      {
        exact: true,
        path: '/docs/state-management',
        component: lazy(() => import('src/views/docs/StateManagementView')),
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() => import('src/views/docs/ThemingView')),
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView')),
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView')),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: '*',
    layout: DashboardLayout,
    guard: AuthGuard,

    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('src/views/offers/OffersListView')),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
]

export default routes
