import { turnStringIntoArray } from 'src/utils/general'

export const APP_VERSION = '3.1.0'

export const ENABLE_REDUX_DEV_TOOLS = true

export const THEMES = {
  LIGHT_MODE: 'LIGHT_MODE',
  DARK_MODE: 'DARK_MODE',
  // UNICORN: 'UNICORN',
}

export const COLORS = {
  lightModeGray: 'rgba(0, 0, 0, 0.23)',
  darkModeGray: 'rgba(255, 255, 255, 0.23)',
  error: '#f44336',
  forestGreen: '#28A745',
}

export const BANNERS = {
  LANDING: 'LANDING',
  OFFERS: 'OFFERS',
  BRANDS: 'BRANDS',
  CATEGORIES: 'CATEGORIES',
  ADVERTISEMENTS: 'ADVERTISEMENTS',
  EVENTS: 'EVENTS',
}

const acceptableTypesForSingleImageUploadDefaultValue = ['jpg', 'jpeg', 'png']
const acceptableTypesForMultipleImageUploadDefaultValue = ['jpg', 'jpeg', 'png', 'gif']

export const ACCEPTABLE_TYPES_FOR_SINGLE_IMAGE_UPLOAD = process.env.REACT_APP_CROPPABLE_IMAGE_TYPES
  ? turnStringIntoArray(process.env.REACT_APP_CROPPABLE_IMAGE_TYPES, ',')
  : acceptableTypesForSingleImageUploadDefaultValue

export const ACCEPTABLE_TYPES_FOR_MULTIPLE_IMAGES_UPLOAD =
  process.env.REACT_APP_CROPPABLE_IMAGE_TYPES && process.env.REACT_APP_UNCROPPABLE_IMAGE_TYPES
    ? turnStringIntoArray(
        process.env.REACT_APP_CROPPABLE_IMAGE_TYPES.concat(`,${process.env.REACT_APP_UNCROPPABLE_IMAGE_TYPES}`),
        ','
      )
    : acceptableTypesForMultipleImageUploadDefaultValue

export const MAXIMUM_IMAGE_SIZE_ALLOWED = process.env.REACT_APP_MAXIMUM_IMAGE_SIZE_ALLOWED
  ? process.env.REACT_APP_MAXIMUM_IMAGE_SIZE_ALLOWED
  : 5 //5MB

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

export const DEFAULT_COUNTRY_CODE = 'UAE'

export const COUNTRIES_CURRENCIES = {
  UAE: 'AED',
  SA: 'SAR',
}

export const ENDPOINTS = {
  categories: {
    all: '/categories?parentId=null&size=10000&order[name]=asc',
    byId: '/categories/:id',
    deleteCategory: 'categories/:id',
    updateCategory: 'categories/:id',
  },
  brands: {
    all: '/brands?order[id]=desc&size=10000',
    byId: '/brands/:id',
    deleteBrand: 'brands/:id',
    updateBrand: 'brands/:id',
  },
  offers: {
    all: '/offers?order[id]=desc&size=10000',
    byId: '/offers/:id?with[]=unpublished',
  },
  countries: {
    all: '/countries',
  },
  Stores: {
    all: '/stores?order[id]=desc&size=10000',
    byId: '/stores/:id',
    deleteStore: 'stores/:id',
    updateStore: 'stores/:id',
  },
  AffiliateNetworks: {
    all: '/affiliateNetworks',
  },
  coupons: {
    all: '/coupons',
    byId: '/coupons/:id',
    deleteCoupon: 'coupons/:id',
  },
  currencies: {
    all: '/currencies',
  },
  banners: {
    all: '/banners',
    deleteBanner: 'banners/:id',
    updateBanner: 'banners/:id',
  },
  advertisements: {
    all: '/advertisements',
    deleteAdvertisements: 'advertisements/:id',
    updateAdvertisements: 'advertisements/:id',
  },
  events: {
    pullData: '/events/pullData',
    events: {
      all: '/events/events',
      byId: '/events/events/:id',
      update: '/events/events/:id',
      options: '/events/eventOptions/:eventId',
      create: '/events/events',
    },
    categories: {
      all: '/events/categories',
      create: '/events/categories',
      update: '/events/categories/:id',
      del: '/events/categories/:id',
    },
    instructions: {
      all: '/events/instructions',
      create: '/events/instructions',
      update: '/events/instructions/:id',
      del: '/events/instructions/:id',
    },
    suppliers: {
      all: '/events/suppliers',
    },
    orders: {
      all: '/events/orders',
      resolve: '/events/orders/resolve',
      byId: '/events/orders/:id',
    },
  },
  tracker: {
    transactions: {
      all: '/trackers/transactions',
    },
    websites: {
      all: '/trackers/websites',
      create: '/trackers/websites',
      edit: '/trackers/websites/:id',
    },
  },
  discountCodes: {
    all: '/discountCodes?order[createdAt]=desc',
    activeCodes: '/discountCodes?filter[isActive]=true',
    changeActivation: '/discountCodes/changeActivation/:id',
    create: '/discountCodes',
    transactions: '/discountCodes/transactions',
  },
  shopify: {
    products: {
      all: '/shopify/products',
      add: '/shopify/products/:id',
      edit: '/shopify/products/:id',
      one: '/shopify/products/:id',
    },
  },
}
